const API_ROOT = process.env.API_ROOT;
const NODE_ENV = process.env.NODE_ENV;
const SOCKET_ROOT = process.env.SOCKET_ROOT;
const VERSION = process.env.VERSION;
const APPLICATION_ACCESS_ID = process.env.APPLICATION_ACCESS_ID;
const APPLICATION_ACCESS_KEY = process.env.APPLICATION_ACCESS_KEY;
const REDIRECT_URL = process.env.REDIRECT_URL;
const SSO_URL = process.env.SSO_URL;
const SSO_HOME = process.env.SSO_HOME;
const SSO_LOGOUT = process.env.SSO_LOGOUT;
const SSO_ACCOUNT = process.env.SSO_ACCOUNT;
export {
    API_ROOT,
    NODE_ENV,
    SOCKET_ROOT,
    VERSION,
    APPLICATION_ACCESS_ID,
    APPLICATION_ACCESS_KEY,
    REDIRECT_URL,
    SSO_URL,
    SSO_HOME,
    SSO_LOGOUT,
    SSO_ACCOUNT
};