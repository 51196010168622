import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';

import { Logo, ProfileImage } from "@src/assets/images";
import { Avatar, Button, Container, Divider, Grid, Link, SwipeableDrawer, Typography } from '@mui/material';
import { CS } from "@src/assets/styles/common-styles";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getLoggedInUserFromStorage } from '../../helper/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCogs, faHistory, faHomeUser, faPhone, faSearch, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../assets/styles/colors';
import { API_URLS, SSO_HOMR_URL, SSO_LOGOUT_URL ,SSO_ACCOUNT_URL} from '../../constants';

const drawerWidth = 320;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

function stringAvatar(name) {
    if (name && name.length > 0) {
        return {
            sx: {
                backgroundColor: stringToColor(name),
                width: 60, height: 60, m: "auto"
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    return {}
}


export default function MiniDrawer(props) {
    let commonStyle = CS();
    const theme = useTheme();
    let navigate = useNavigate();
    const [openUserDrawer, setOpenUserDrawer] = React.useState(false);
    const [loggedInUser, setLoggedInUser] = React.useState(null);

    useEffect(() => {
        let loggedInUser = getLoggedInUserFromStorage();
        setLoggedInUser(loggedInUser);
    }, []);


    /**
    * This being used in anchor quick link open menu
    */
    const [anchorQuickLinkEl, setAnchorQuickLinkEl] = React.useState(false);

    /**
     * This method help to parametrized navigation to any path
     * @param {*} path 
     */
    const navigateTo = (path) => {
        navigate(path);
    }
    /**
     * Inner content for the user menu
     * @returns 
     */

    const InnerContent = () => {
        let fullName = loggedInUser?.full_name ? loggedInUser?.full_name : "";
        let emailAddress = loggedInUser?.email_address ? loggedInUser?.email_address : "";
        return (
            <Box
                sx={{ width: drawerWidth, p: 0 }}
                role="presentation"
                onClick={toggleUserDrawer(false)}
                onKeyDown={toggleUserDrawer(false)}
            >
                <div>
                    <table className={`${commonStyle.headerTable}`}>
                        <tbody>
                            <tr className={`${commonStyle.vt}`}>
                                <td>
                                    <Container maxWidth="full" sx={{ p: `0 !important` }}>
                                        <Grid container direction="column">
                                            <Grid item sx={{ mt: "16px", textAlign: "center" }}>
                                                <Avatar {...stringAvatar(fullName)} />
                                                <Typography sx={{ mt: 3 }} className={`${commonStyle.textPrimary} ${commonStyle.fw600}`}>{fullName}</Typography>
                                                <Typography className={`${commonStyle.textDark}`}>{emailAddress}</Typography>
                                            </Grid>

                                            <Divider sx={{ mt: 2 }} />
                                            <Grid item sx={{ mt: "16px", pl: '16px' }} >
                                                <Typography component={"h5"} className={`${commonStyle.textDark} ${commonStyle.fw600}`}>
                                                    <FontAwesomeIcon icon={faCogs} className={`${commonStyle.textPrimary}`} />
                                                    &nbsp;
                                                    Account
                                                </Typography>
                                                <Grid sx={{ pl: '22px', mt: 1, pl: 3 }}>
                                                    <Link href={SSO_ACCOUNT_URL} className={`${commonStyle.cp}`} color={COLORS.GREY1} underline="hover">
                                                        Settings
                                                    </Link>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ mt: 2 }} />
                                            <Grid item sx={{ mt: "16px", pl: '16px' }} >
                                                <Typography component={"h5"} className={`${commonStyle.textDark} ${commonStyle.fw600}`}>
                                                    <FontAwesomeIcon icon={faPhone} className={`${commonStyle.textPrimary}`} />
                                                    &nbsp;
                                                    Info
                                                </Typography>
                                                <Grid sx={{ pl: '22px', mt: 1, pl: 3 }}>
                                                    <Grid>
                                                        <Link omponent="a" href={API_URLS.help_pdf_url} target="_blank" className={`${commonStyle.cp}`} color={COLORS.GREY1} underline="hover">
                                                            Help / Documentation
                                                        </Link>
                                                    </Grid>

                                                    <Grid>
                                                        <Link component="a" href="https://graphemelabs.com" target="_blank" className={`${commonStyle.cp}`} color={COLORS.GREY1} underline="hover">
                                                            About Us
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </td>
                            </tr>
                            <tr className={`${commonStyle.ht64px}`}>
                                <td className={`${commonStyle.bgSecondary}`}>
                                    <Button className={`${commonStyle.textWhite}`} sx={{ width: "100%" }} href={SSO_LOGOUT_URL}>
                                        <FontAwesomeIcon icon={faSignOut} />
                                        &nbsp;
                                        Logout
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        )
    }
    /**
     * Toggle drawer for user icon click
     * @param {*} open 
     * @returns 
     */
    const toggleUserDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpenUserDrawer(open);
    };

    const handleQuickLinkNavigate = (linkToNav) => {
        setAnchorQuickLinkEl(null);
        setTimeout(() => {
            linkToNav && navigateTo(linkToNav);
        }, 20);
    };



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Container maxWidth="full">
                    <Toolbar disableGutters>
                        <Grid
                            component={"div"}
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => { navigateTo("/") }}
                            sx={{
                                mt: "5px",
                                cursor: "pointer"
                            }}
                        >
                            <img src={Logo} height="32" />
                        </Grid>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">
                                <Grid item>
                                    <Button variant="text" className={`${commonStyle.textDark}`} sx={{ mr: "10px", pl: "8px", pr: "8px", minWidth: "auto" }} href={SSO_HOMR_URL}>
                                        <Typography component={"h6"} className={`${commonStyle.textCapitalize}`}>
                                            <FontAwesomeIcon className={`${commonStyle.textPrimary} ${commonStyle.fs9}`} icon={faHomeUser} />
                                            &nbsp;
                                            Home
                                        </Typography>
                                    </Button>

                                    <Button variant="text" className={`${commonStyle.textDark}`} sx={{ mr: "10px", pl: "8px", pr: "8px", minWidth: "auto" }} onClick={() => { navigateTo("/") }}>
                                        <Typography component={"h6"} className={`${commonStyle.textCapitalize}`}>
                                            <FontAwesomeIcon className={`${commonStyle.textPrimary} ${commonStyle.fs9}`} icon={faSearch} />
                                            &nbsp;
                                            Search
                                        </Typography>
                                    </Button>
                                    <Button variant="text" className={`${commonStyle.textDark} `} sx={{ mr: "10px", pl: "8px", pr: "8px", minWidth: "auto" }} onClick={() => { navigateTo("/search/history") }}>
                                        <Typography component={"h6"} className={`${commonStyle.textCapitalize}`}>
                                            <FontAwesomeIcon className={`${commonStyle.textPrimary} ${commonStyle.fs9}`} icon={faHistory} />
                                            &nbsp;
                                            History
                                        </Typography>
                                    </Button>
                                    <Button variant="text" sx={{ pl: "8px", pr: "8px", minWidth: "auto" }}  onClick={toggleUserDrawer(true)}>
                                        <Avatar alt="Profile" sx={{ width: 28, height: 28, mr: 1 }} src={ProfileImage} />
                                        <FontAwesomeIcon className={`${commonStyle.textLight} ${commonStyle.fs8}`} icon={faCaretDown} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Box component="main" sx={{ flexGrow: 1 }}>
                <DrawerHeader />
                <SwipeableDrawer
                    open={openUserDrawer}
                    anchor={"right"}
                    onClose={toggleUserDrawer(false)}
                    onOpen={toggleUserDrawer(true)}
                    sx={{ zIndex: '1300 !important' }}
                >
                    <InnerContent />
                </SwipeableDrawer>

                <Grid sx={{ position: 'fixed', bottom: '0px', left: '0px', right: '0px', textAlign: 'center', backgroundColor: "#f1f1f1", zIndex: 999 }}>
                    <Typography sx={{ fontSize: '10px', p: '4px' }}>
                        © Copyright 2024 | Grapheme Labs Private Limited
                    </Typography>
                </Grid>

            </Box>
        </Box>
    );
}
